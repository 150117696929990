@import 'angularjs-slider/dist/rzslider';
@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/slider';

.widget__playlist {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;

	&-item {
		display: flex;
    flex-direction: column;
    gap: .5rem;
    width: calc(50% - 1rem);

	  &.active {
	    color: $color9;
	  }
	}

	&-track {
		display: flex;
	  flex-direction: column;
	  gap: .5rem;
	  margin-bottom: .5rem;
	}

	&-title {
		font-size: $font-size3;
		display: contents;
		cursor: pointer;

		&:hover {
			opacity: $opacity;
		}
	}

	&-level {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
	}
}

.info-container__item:first-child {
	padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}