@import 'variables';

.rzslider {
  margin: 0;
  height: 2px;

  .rz-bubble.rz-limit {
    color: #55637d;
    transition: none;
    color: #333;
    font-size: 14px;
    line-height: 1;
    padding: 3px 5px;
    background-color: $color2;
    border-radius: 4px;
    display: none;
  }

  .rz-bubble {
    bottom: 16px;
    padding: 1px 3px;
    color: #55637d;
    cursor: default;
    transition: all linear .3s;
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: $color2;
    border-radius: 4px;
    display: none;
  }

  .rz-bar {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 2px;
    background: transparent;
    border-radius: 0;

		&-wrapper {
			visibility: visible !important;

			&.rz-right-out-selection .rz-bar {
        background: $color;
			}
		}
  }

  .rz-pointer {
    top: -7px;
    z-index: 3;
    cursor: pointer;
    background-color: $color2;
    transition: all linear .3s;
    width: 2rem;
    height: 1rem;
    border: 2px solid $color;
    border-radius: 1rem;

    &:after {
      display: none;
    }
  }

  .rz-selection {
    background: $color9 !important;
  }

  &[disabled] {
    cursor: default;

		.rz-selection {
			background: $color !important;
		}

	  .rz-pointer {
	    cursor: default;
	    background-color: $color2;
		}
  }
}